.block {
  background-color: #fff;
  height: calc(100% - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 180px;
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #F2F2F3;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #47361E;
    -webkit-box-shadow: none;
  }
  .header {
    display: none;
    position: static !important;
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: -10%;
    margin-right: -10%;
    width: 120%;
  }
  .topRow {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 0 70px;
    .goBack {
      color: #352208;
      font-size: 16px;
      cursor: pointer;
      transition: .2s;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: row-reverse;
    .video {
      width: 1000px;
      height: 700px;
      position: fixed;
      top: 150px;
      left: 0;
    }
    .text {
      padding-right: 150px;
      padding-bottom: 60px;
      max-width: calc(100% - 1305px);
      h2 {
        color: #352208;
        font-size: 40px;
        letter-spacing: 0.05em;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 35px;
        line-height: 40px;
      }
      p {
        color: #352208;
        font-size: 16px;
        line-height: 27px;
      }
    }
    @media screen and (max-width: 1800px) {
      .video {
        width: 644px;
        height: 400px;
        top: 180px;
      }
      .text {
        max-width: calc(100% - 810px);
        padding-right: 80px;
      }
    }

    @media screen and (max-width: 1300px) {
      .video {
        width: 50%;
      }
      .text  {
        max-width: 35%;
      }
    }

    @media screen and (max-width: 959px) {
      flex-direction: column;
      .video {
        position: static;
        width: 100%;
        height: 80vw * 3 / 4;
      }
      .text {
        max-width: 100%;
        padding-right: 0;
        padding-bottom: 0;

        h2 {
          font-size: 40px;
          margin-top: 35px;
        }
      }
    }

    @media screen and (max-width: 560px) {
      .text {
        h2 {
          font-size: 30px;
          margin-top: 35px;
        }

        p {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 1800px) {
    padding-top: 145px;
  }
  @media screen and (max-width: 959px) {
    padding-top: 0;
    padding-bottom: 30px;
    padding-left: 10%;
    height: calc(100% - 30px);
    padding-right: 10%;

    .topRow {
      .goBack {
        display: none;
      }
    }

    .header {
      display: block;
    }
  }
}