.screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  scroll-behavior: smooth;
  transition: .8s;
  visibility: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important
  }

  &.shown {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  &.sepiabg {
    background-image: url(/img/mainbg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }
}
