:global {
  html, body  {
    -webkit-appearance: none;
  }
}
.block {
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important
  }
  .videoContainer {
    .video {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
.shareContacts {
  position: fixed;
  z-index: 5;
  bottom: 40px;
  padding-left: 70px;
  display: flex;
  align-items: center;
  span {
    display: inline-flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    align-items: center;
    color: #fff;
    &::after {
      content: '';
      width: 67px;
      height: 1px;
      background-color: #fff;
      margin-left: 10px;
      display: inline-block;
    }
  }
  .links {
    a {
      font-size: 16px;
      line-height: 27px;
      color: #fff;
      display: inline-block;
      padding-left: 13px;
      padding-right: 13px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    padding-left: 60px;

    span {
      font-size: 16px;
      &::after {
        width: 45px;
      }
    }

    .link {
      a {
        font-size: 15px;
        padding-right: 5px;
      }
    }
  }
}
.contentIsRead {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 105px;
  display: flex;
  cursor: pointer;
  z-index: 7;
  color: #fff;
  span {
    transform: rotate(90deg) translate(145px, 108px);
    white-space: nowrap;
    display: inline-block;
    min-width: 254px;
    height: 100px;
    letter-spacing: 0.02em;
    font-size: 30px;
    line-height: 40px;
    transition: .2s;
  }
  &::after {
    content: '';
    position: absolute;
    height: calc(100% - 400px);
    width: 1px;
    background-color: #fff;
    bottom: 41px;
    right: 57px;
    transition: 0s;
  }
  &:hover {
    &::after {
      height: calc(100% - 410px);
      transition: .2s;
    }
  }
  &.justWhite {
    background-color: #F2F2F3;
    span {
      color: #352208;
    }
    &::after {
      background-color: #352208;
    }
  }
  &.readersOpened {
    background-color: #F2F2F3;
    span {
      color: #352208;
    }
    &::after {
      background-color: #352208;
      height: calc(100% - 300px);
    }
    &:hover {
      &::after {
        height: calc(100% - 310px);
        transition: .2s;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    width: 80px;

    span {
      font-size: 20px;
    }
    &::after {
      right: 32px;
      height: calc(100% - 320px);
    }
    &:hover {
      &::after {
        height: calc(100% - 330px);
      }
    }
    &.readersOpened {
      &::after {
        height: calc(100% - 240px);
      }
      &:hover {
        &::after {
          height: calc(100% - 250px);
        }
      }
    }
  }
}
.contentIsReadList {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 105px;
  transform: translateX(655px);
  opacity: 0;
  visibility: hidden;
  background-color: #F2F2F3;
  z-index: 6;
  width: 655px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  transition: .4s;
  will-change: transform, opacity;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  &.shown {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
  }
  .scrollable {
    height: 100%;
    height: 100%;
    padding: 0 70px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: transparent;
      -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar {
      width: 2px;
      background-color: #F2F2F3;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #47361E;
      -webkit-box-shadow: none;
    }
  }
  .item {
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &:not(:first-child) {
      border-top: 1px solid #C4C4C4;
    }
    h2 {
      font-size: 21px;
      color: #352208;
      line-height: normal;
      margin: 0;
      transition: .2s;
    }
    .partNum {
      position: absolute;
      font-size: 13px;
      color: #72727e;
      top: 25px;
      right: 0;
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 21px;
      color: #72727E;
      margin-top: 7px;
    }
    &:hover {
      h2 {
        color: #E1BB80;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    right: 79px;
    width: 498px;

    .scrollable {
      padding: 0 30px;
    }

    .item {
      .partNum {
        font-size: 14px;
        top: 28px;
      }
      p {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}