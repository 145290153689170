.header {
  position: fixed;
  top: 45px;
  width: 100%;
  padding-left: 70px;
  z-index: 4;
  .link {
    color: #352208;
    cursor: pointer;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
    opacity: 0.7;
    transition: .2s;
    &:hover {
      opacity: 1;
    }
  }
  &.white {
    .link {
      color: #fff;
    }
  }
  &.black {
    .link {
      color: #352208;
    }
  }
  &.mobile {
    padding-left: 0;
    text-align: center;
    top: 30px;
    .link {
      font-size: 16px;
      line-height: 25px;
      margin: 0 13px;

      @media screen and (max-width: 600px)  {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    padding-left: 60px;
    top: 35px;
    .link {
      font-size: 14px;
      margin-right: 30px;
      line-height: 20px;
    }
  }
}