.block {
  position: fixed;
  height: 100%;
  width: 433px;
  display: flex;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: transform .45s, opacity .35s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  z-index: 1;
  &.right {
    right: 150px;
    transform: rotateY(-75deg);
  }
  &.left {
    right: calc(100% - 537px);
    transform: rotateY(75deg);
  }
  &.active {
    opacity: 1;
    z-index: 2;
    transition-delay: .25s;
    &.right {
      transform: rotateY(0);
    }
    &.left {
      transform: rotateY(0);
    }
  }

  @media screen and (max-width: 1300px) {
    width: 260px;

    &.right {
      right: 80px;
    }
    &.left {
      right: calc(100% - 320px);
    }
  }
}
.content {
  width: 100%;
}
.card {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  cursor: pointer;

  &:not(.opened) {
    &:hover {
      h3 {
        color: #E1BB80;
      }
    }
    &:not(:first-child) {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        width: calc(100% - 40px);
        left: 20px;
        height: 1px;
        background-color: rgba(114, 114, 126, 0.5);
      }
    }
  }

  .avatar {
    width: 90px;
    height: 90px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin-right: 20px;
  }

  h3 {
    font-size: 24px;
    color: #352208;
    line-height: 27px;
    text-align: left;
    transition: .2s
  }

  .buttonOpen {
    display: none;
    width: 190px;
    border: 1px solid #91816C;
    cursor: pointer;
    text-align: center;
    line-height: 42px;
    font-size: 16px;
    color: #91816C;
    transition: .2s;
    &:hover {
      color: #47361E;
      border-color: #47361E;
    }
  }
  &.opened {
    height: 100px;
    background-color: #FFEEDB;
    height: 454px;
    justify-content: center;
    flex-direction: column;
    cursor: initial;

    h3 {
      text-align: center;
    }

    & + .card {
      &::after {
        display: none;
      }
    } 

    .buttonOpen {
      display: inline-block;
    }

    .avatar {
      width: 200px;
      height: 200px;
      margin-right: 0;
    }
  }


  @media screen and (max-width: 1300px) {
    .avatar {
      width: 50px;
      height: 50px;
    }
    .buttonOpen {
      font-size: 14px;
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }
    &.opened {
      height: 285px;
      .avatar {
        width: 100px;
        height: 100px;
      }
      h3 {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
}
