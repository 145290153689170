html {
  min-height: 100%;
  position: relative;
}
body {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important
  }
}
#scrollScreen {
  overflow-y: scroll;
}
#root {
  min-height: 100%;
}