.content {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  color: #352208;
  max-width: 538px;
  margin: 0 auto;
  overflow-y: auto;
  h2 {
    font-size: 24px;
    letter-spacing: 0.05em;
    line-height: 32px;
    font-weight: 900;
    margin-top: -7px;
    margin-bottom: 10px;
  }
  img {
    margin-bottom: -13px;
  }
  p {
    font-size: 12px;
    line-height: 20px;
  }
  .warning {
    font-weight: 500;
    max-width: 412px;
    margin: 40px auto 0;

  }
}
.header {
  display: block;
  position: static;
  margin-top: 35px;
}
.block {
  height: 100%;
  overflow-y: auto;
}

@media screen and (max-width: 600px)  {
  .header {
    margin-top: 25px;
  }
  .content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 10px;
    h2 {
      font-size: 18px;
      line-height: 27px;
    }
    p {
      font-size: 10px;
      line-height: 20px;
    }

    .warning {
      margin-top: 24px;
    }
  }
}