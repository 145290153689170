.fact {
  opacity: 0;
  transition: .3s;
  position: absolute;
  bottom: 80px;
  left: calc(50% - 365px);
  text-align: center;
  width: 730px;

  h2 {
    margin: 0;
    font-size: 50px;
    font-weight: 900;
    line-height: 67px;
    margin-bottom: 25px;
    letter-spacing: 0.05em;
  }
  p {
    font-size: 16px;
    line-height: 27px;
    margin: 0;
  }

  &.show {
    opacity: 1;
  }

  @media screen and (max-width: 1300px) {
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.progressBar {
  width: 620px;
  height: 2px;
  position: relative;
  background-color: rgba(53, 34, 8, .5);
  .indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    transition: .2s;
    background-color: rgba(53, 34, 8, 1);
  }
  .numeric {
    position: absolute;
    top: 18px;
    left: calc(50% - 50px);
    color: #352208;
    opacity: 0.5;
    font-size: 30px;
    line-height: 39px;
    width: 100px;
  }

  @media screen and (max-width: 1300px) {
    .numeric {
      font-size: 25px;
      line-height: 33px;
      
    }
  }
}
.block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .inner {
    padding-top: 300px;
    position: relative;
  }

  @media screen and (max-width: 1300px) {
    .inner {
      padding-top: 230px;
    }
  }
}