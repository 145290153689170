.block {
  width: 959px;
  position: fixed;
  z-index: 6;
  right: 105px;
  top: 0;
  bottom: 0;
  background-color: #F2F2F3;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  transform: translateX(959px);
  transition: .5s;
  opacity: 0;
  will-change: transform, opacity;
  visibility: hidden;
  &.opened {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
  }
  .prevNext {
    color: #352208;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 30px;
    span {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      &:first-child {
        &::before {
          content: '';
          margin-right: 9px;
          width: 20px;
          transition: .2s;
          height: 10px;
          background-image: url(/img/arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        &:hover {
          &::before {
            width: 25px;
          }
        }
      }
      &:last-child {
        &::after {
          content: '';
          transform: rotate(180deg);
          margin-left: 9px;
          width: 20px;
          transition: .2s;
          height: 10px;
          background-image: url(/img/arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        &:hover {
          &::after {
            width: 25px;
          }
        }
      }
      &.hidden {
        visibility: hidden;
      }
    }
  }
  .contentBlock {
    padding: 30px 150px;
    height: calc(100% - 500px);
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: transparent;
      -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar {
      width: 2px;
      background-color: #F2F2F3;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #47361E;
      -webkit-box-shadow: none;
    }
    .readBy {
      color: #352208;
      font-size: 16px;
      line-height: 27px;
      & > p {
        &:first-child {
          margin-bottom: 11px;
        }
      }
      .readerLink {
        &:not(:first-child) {
          margin-left: 30px;
        }
        color: rgba(53, 34, 8, .5);
        transition: .2s;
        position: relative;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          background-color: #352208;
          opacity: 0;
          transition: .2s;
          bottom: -5px;
        }
        &:hover, &.active {
          color: #352208;
          &::after {
            opacity: 1;
          }
        }
      }
    }
    .content {
      h2 {
        margin-top: 30px;
        margin-bottom: 10px;
        color: #352208;
        letter-spacing: 0.05em;
        font-size: 40px;
        font-weight: 800;
        line-height: normal;
      }
      h3 {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        color: #72727E;
      }
      p {
        font-size: 16px;
        line-height: 27px;
        color: #352208;
      }
    }
  }
  .hide {
    width: 120px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -120px;
    cursor: pointer;
    span {
      transform: rotate(90deg) translate(120px, 0);
      color: #fff;
      letter-spacing: 0.02em;
      font-size: 30px;
      display: inline-block;
    }
    &::after {
      content: '';
      position: absolute;
      right: 46px;
      bottom: 41px;
      width: 1px;
      height: calc(100% - 300px);
      background-color: #fff;
      transition: .2s;
    }
    &:hover {
      &::after {
        height: calc(100% - 310px);
      }
    }
  }

  @media screen and (max-width: 1300px) {
    width: 500px;
    right: 79px;

    .hide {
      width: 80px;
      left: -80px;
      span {
        font-size: 20px;
      }
      &::after {
        right: 31px;
        height: calc(100% - 250px);
      }
      &:hover {
        &::after {
          height: calc(100% - 260px);
        }
      }
    }

    .contentBlock {
      padding: 20px 30px;
      height: calc(100% - 323px);

      .readBy {
        font-size: 14px;
        line-height: 24px;
      }

      .content {
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
        h3 {
          font-size: 14px;
          line-height: 20px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .prevNext {
      font-size: 14px;
    }
  }
}