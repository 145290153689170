.block {
  background-color: #fff;
  position: relative;
  height: calc(100% - 277px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 147px 150px 130px;
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar {
    width: 2px;
    background-color: #F2F2F3;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #47361E;
    -webkit-box-shadow: none;
  }
  .header {
    display: none;
    position: static !important;
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: -10%;
    margin-right: -10%;
    width: 120%;
  }
  .topRow {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 0 70px;
    .goBack {
      color: #352208;
      font-size: 16px;
      cursor: pointer;
      transition: .2s;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  h2 {
    color: #352208;
    font-size: 40px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 60px;
    letter-spacing: 0.05em;
    line-height: 40px;
  }
  .contentIsReadList {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 46%;
    }
    .item {
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      border-bottom: 1px solid #C4C4C4;
      h2 {
        font-size: 21px;
        color: #352208;
        line-height: normal;
        margin: 0;
        transition: .2s;
        letter-spacing: initial;
      }
      .partNum {
        position: absolute;
        font-size: 13px;
        color: #72727e;
        top: 30px;
        right: 0;
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 21px;
        color: #72727E;
        margin-top: 7px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .contentIsReadList {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 959px) {
    padding-top: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 30px;
    height: calc(100% - 30px);
    .header {
      display: block;
    }

    .topRow {
      .goBack {
        display: none;
      }
    }
  }

  @media screen and (max-width: 560px) {
    padding-left: 10%;
    padding-right: 10%;

    h2 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    .contentIsReadList {
      .item {
        padding: 20px 0;
        .partNum {
          position: static;
          display: block;
          margin-top: 7px;
          margin-bottom: 13px;
          font-size: 12px;
          line-height: 16px;
        }
        h2 {
          font-size: 16px;
          line-height: 22px;
        }
        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}