.block {
  display: flex;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;

  .right {
    position: relative;
    .gallery {
      transform: translateY(0);
      transition: transform 60s;
      transition-timing-function: linear;
      will-change: transform;
      padding-left: 60px;
      .film {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .images {
        columns: 2;
        column-gap: 50px;
      }
      .img {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        box-shadow: 0px 0px 50px rgba(105, 74, 39, 0.5);
        &1 {
          width: 314px;
          height: 293px;
          margin: 70px auto;
        }
        &2 {
          width: 385px;
          height: 521px;
        }
        &3 {
          width: 471px;
          height: 617px;
          margin: 0 auto;
        }
        &4 {
          width: 301px;
          height: 389px;
          margin-top: 70px;
          margin-bottom: 70px;
        }
      }
      .profile {
        &1 {
          background-image: url(/img/1.jpg);
        }
        &2 {
          background-image: url(/img/2.jpg);
        }
        &3 {
          background-image: url(/img/3.jpg);
        }
        &4 {
          background-image: url(/img/4.jpg);
        }
        &5 {
          background-image: url(/img/5.jpg);
        }
        &6 {
          background-image: url(/img/6.jpg);
        }
        &7 {
          background-image: url(/img/7.jpg);
        }
        &8 {
          background-image: url(/img/8.jpg);
        }
        &9 {
          background-image: url(/img/9.jpg);
        }
        &10 {
          background-image: url(/img/10.jpg);
        }
        &11 {
          background-image: url(/img/11.jpg);
        }
        &12 {
          background-image: url(/img/12.jpg);
        }
        &13 {
          background-image: url(/img/13.jpg);
        }
        &14 {
          background-image: url(/img/14.jpg);
        }
        &15 {
          background-image: url(/img/15.jpg);
        }
        &16 {
          background-image: url(/img/16.jpg);
        }
      }
    }

    @media screen and (max-width: 1700px) {
      width: calc(100% - 630px);
      .gallery {
        column-gap: 35px;
        width: 625px;
        margin: 0 auto;
        .profile {
          &2 {
            margin-top: 200px;
          }
        }
        .img {
          &1 {
            width: 205px;
            height: 192px;
            margin: 50px auto;
          }
          &2 {
            width: 249px;
            height: 338px;
          }
          &3 {
            width: 265px;
            height: 347px;
          }
          &4 {
            width: 192px;
            height: 248px;
            margin-top: 50px;
            margin-bottom: 50px;
          }
        }
      }
    }
    @media screen and (max-width: 1300px) {
      width: 550px;
      .gallery {
        padding-left: 35px;
        .film {
          width: 80%;
        }
        .img {
          &1 {
            margin: 35px auto;
            width: 153px;
            height: 144px;
          }
          &2 {
            width: 187px;
            height: 253px;
          }
          &3 {
            width: 198px;
            height: 260px;
          }
          &4 {
            width: 144px;
            height: 186px;
            margin-top: 35px;
            margin-bottom: 35px;
          }
        }
        .images {
          column-gap: 0
        }
      }
    }
  }

  .left {
    position: relative;
    margin-left: 140px;
    align-items: center;
    display: flex;
    max-width: 630px;
    padding-bottom: 110px;
    
    .smallText {
      font-size: 16px;
      line-height: 27px;
      color: #352208;
      padding: 0;
      margin: 0;
    }
    .bottomContent {
      position: absolute;
      bottom: 50px;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
    }
    .mouse {
      display: flex;
      align-items: center;
      margin-right: 70px;
      cursor: pointer;

      .img {
        width: 35px;
        height: 58px;
        background-size: contain;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url(/img/fin.gif);
        margin-right: 20px;
      }
    }
    h2 {
      font-weight: 900;
      line-height: 67px;
      font-size: 50px;
      letter-spacing: 0.05em;
      color: #352208;
      margin-top: 7px;
      margin-bottom: 40px;
    }
    .withSupport {
      text-align: right;
      .logos {
        display: inline-flex;
        align-items: center;
        margin-top: 20px;
      }
      .petroLogo {
        background-image: url(/img/petro-logo.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 82px;
        height: 32px;
        display: inline-block;
      }
      .gorodPlus {
        background-image: url(/img/gorodplus.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 86px;
        height: 34px;
        display: inline-block;
        margin-right: 20px;
      }
    }

    @media screen and (max-width: 1700px) {
      margin-left: 80px;
    }
    @media screen and (max-width: 1300px) {
      width: calc(100% - 550px);
      margin-left: 60px;

      .smallText {
        font-size: 14px;
        line-height: 24px;
      }

      h2 {
        font-size: 30px;
        line-height: 40px;
        margin-top: 4px;
        margin-bottom: 20px;
      }

      .mouse {
        margin-right: 0;
        .img {
          margin-right: 14px;
        }
      }

      .withSupport {
        .petroLogo {
          margin-top: 10px;
        }
      }
    }
    @media screen and (max-width: 1045px) {
      width: calc(100% - 500px);
      margin-left: 25px;
      padding-bottom: 164px;
    }
    @media screen and (max-width: 1000px) {
      .bottomContent {
        bottom: 35px;
      }
      .withSupport {
        .logos {
          flex-direction: column;
          margin-top: 7px;
          .gorodPlus {
            margin-right: 0;
            margin-bottom: 7px;
          }
          .petroLogo {
            width: 82px;
          }
        }
      }
    }
    @media screen and (min-width: 1301px) and (max-height: 700px) {
      h2 {
        margin-top: 5px;
        margin-bottom: 30px;
        line-height: 59px;
        font-size: 48px;
      }
      .bottomContent {
        bottom: 30px;
      }
    }
  }
}